import { PageVisitMetrics } from "@sipmon/reactcomponents";
import { toNumber } from "lodash";
import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state";
import { getPageViewsByUrl, selectPageviews } from "../../common/state/pageViewsByUrlReducer";



const PageVisitsMetrics = () => {
    const dispatch = useAppDispatch();
    const { pageViews, isLoading } = useAppSelector(selectPageviews);
    let count = 0;

    // Method to retrive page visit details
    const getPageVisitInfo = useCallback(async () => {
        resetStateValues();
        let response;
        if (pageViews) {            
            // Getall all the data for the current page
            response = pageViews.filter((d) => d.pageURL === window.location.href.toLowerCase());
            if (response) {
                // Total visit till now
                count = response.length;
                setTotalVisit(count == null ? 0 : toNumber(count));

                // Total visit in 180 days
                let curDate = new Date(new Date());
                curDate.setDate(curDate.getDate() - 180);
                let res = response.filter((d) => new Date(d.timestamp) >= curDate);
                setTotalVisitIn180days(count == null ? 0 : toNumber(res.length));

                // Total visit in 30 days
                curDate = new Date(new Date());
                curDate.setDate(curDate.getDate() - 30);
                res = response.filter((d) => new Date(d.timestamp) >= curDate);
                setTotalVisitIn30days(count == null ? 0 : toNumber(res.length));
            }
        }
    }, [pageViews]);

    React.useEffect(() => {
        dispatch(getPageViewsByUrl(window.location.href));
    }, []);

    React.useEffect(() => {
        getPageVisitInfo();
    }, [pageViews]);

    function resetStateValues() {
        setTotalVisit(0);
        setTotalVisitIn180days(0);
        setTotalVisitIn30days(0);
    }
       
    const [totalVisit, setTotalVisit] = React.useState<number>(0);
    const [totalVisitIn180days, setTotalVisitIn180days] = React.useState<number>(0);
    const [totalVisitIn30days, setTotalVisitIn30days] = React.useState<number>(0);

    return (<PageVisitMetrics isLoading={isLoading} totalVisit={totalVisit} totalVisitIn180days={totalVisitIn180days} totalVisitIn30days={totalVisitIn30days} />)   
};

export default PageVisitsMetrics;
