import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { dataCatalogClient } from "../clients";
import { IPageView } from "../models/PageView";

interface PageViewsByUrlState {
    pageViews?: IPageView[] | undefined;
    errors?: string;
    isLoading: boolean;

}
export const sliceName = "pageviews";

const initialState: PageViewsByUrlState = {
    pageViews: [],
    errors: "",
    isLoading: false
};

// Get page views for the current page
const getPageViewsByUrl = createAsyncThunk(
    `${sliceName}/get_pageviews_by_url`,
    async (curUrl:string) => {
        const response = await dataCatalogClient.post<IPageView[]>(`datacatalogfeature/getpageviewsbyurl`, { curUrl });
        return response.data;
    }
);

export const pageViewsByURLSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Retrive page views by url
        builder.addCase(getPageViewsByUrl.pending, (state, _action) => {
            state.isLoading = true;
            state.errors = "";
        });
        builder.addCase(getPageViewsByUrl.fulfilled, (state, _action) => {
            state.isLoading = false;
            state.errors = "";
            state.pageViews = _action.payload;
        });
        builder.addCase(getPageViewsByUrl.rejected, (state, _action) => {
            state.isLoading = false;
            state.errors = "Error occured while retrieving page views";
        });
    }
});

export { getPageViewsByUrl }

export const selectPageviews = (state: RootState) => state.pageViews

export default pageViewsByURLSlice.reducer;
