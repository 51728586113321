import { IPanelProps, IRenderFunction, Icon, Image, Panel, PanelType, ThemeProvider } from "@fluentui/react";
import { CoherenceCustomizations } from "@coherence-design-system/styles";
import { MsalAuthenticationTemplate, MsalProvider, useAccount } from "@azure/msal-react";
import { DatasetsView } from "./features/DataSets";
import {
    AccountInfo,
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionType,
    IPublicClientApplication
} from "@azure/msal-browser";
import { authenticationParameters, getUserDisplayName, getUserName } from "./common/auth/MsalConfig";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { RbacGuard } from "./common/auth/RouteGuard";
import { AccessRoles } from "./common/auth";
import { Provider } from "react-redux";
import store from "./common/state/store";
import { useEffect, useState } from "react";
import { getUserPhoto } from "./features/Header";
import { selectNotifications, setIsPreviewEnabled } from "./common/state/notificationsReducer";
import { useAppDispatch, useAppSelector } from "./common/state";
import { telemetryConfig, TelemetryService } from "./common/telemetry";
import { IPageViewTelemetry } from "@microsoft/applicationinsights-web";
import { InfoPanelContent } from "./features/DataSets/Content/InfoPanelContent";
import { addNotification } from "./common/state/notificationsReducer";
import Logo from "./assets/images/DC_Public_Preview.png";
import { clearError, selectError } from "./common/state/errorReducer";
import { getNotificationItem, NotificationTypes } from "./common/notifications";
import { useGetAllDataSetsQuery } from "./features/DataSets/slice";
import NotFound from "./NotFound";
import { getHelpPanelContent } from "./features/Header/HelpPanelContent";
import useIsAdmin from "./common/hooks/useIsAdmin";
import { dataCatalogClient } from "./common/clients";
import { DataPrivacyAlert } from "./common/components/DataPrivacyAlert";
import { useGetPageViewsDetailsQuery } from "./common/state/pageviewReducer";
import { DataSetSummary } from "./features/DataSets/DataSetSummary.types";
import { OBOAccessRequestIntake } from "./features/DataSets/OBOAccessRequestIntake";
import { Header } from "./features/Header/Header";
import RoleGroupOboRequestForm from "./features/DataSetCollections/RoleGroupOboRequestForm";
import { DatasetCollectionGrid } from "./features/DataSetCollections/DatasetCollectionGrid/DatasetCollectionGrid";
import FeedbackModal from "./features/DataSets/Content/FeedbackModal";
import AuthenticatedAccessRequestPage from "./features/UnAuthenticated/AuthenticatedAccessRequest";
import { SippyChatBot } from "./features/SippyChatBot/SIPPYChat";
import sippyLogo from "./assets/images/Sippy-Tab-V1.png";
import AskSippyLogo from "./assets/images/AskSippyV0.png";
import { IsSippyEnabledAction, selectSippyChatStates } from "./common/state/sippyChatReducer";

const Inner = ({ msalInstance }: { msalInstance: IPublicClientApplication }) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const account = useAccount() as Required<AccountInfo>;
    const { notifications, isPreviewEnabled } = useAppSelector(selectNotifications);
    const { isSippyFeatureEnabled } = useAppSelector(selectSippyChatStates);
    const { currentError } = useAppSelector(selectError);
    const [dataSets, setDataSets] = useState<DataSetSummary[]>();
    const [skip, setSkip] = useState<boolean>(true);
    const [displayFeedback, setDisplayFeedback] = useState<boolean>(false);
    const [showChat, setShowChat] = useState<boolean>(false);
    const [dsName, setDsName] = useState<string>("");
    const navigate = useNavigate();
    const isAdmin = useIsAdmin();

    // make toast like notifications, any error sent via the centeralized error handler will display whereever and then
    // clear after 5 sec. A notification entry will be made
    useEffect(() => {
        if (currentError) {
            dispatch(addNotification(getNotificationItem(NotificationTypes.Error, currentError.message, "Error")));
            setTimeout(() => {
                dispatch(clearError());
            }, 5000);
        }
    }, [currentError]);

    // refetch every 5 mins
    const { data, isLoading, refetch } = useGetAllDataSetsQuery(undefined, { pollingInterval: 600000 });

    // wait till we received dataset
    const { data: dsVisitCounts } = useGetPageViewsDetailsQuery("pageViewsAPI", { skip });

    // merging hit count with dataset
    useEffect(() => {
        try {
            if (dataSets && dsVisitCounts) {
                let dSets = dataSets.map((e) => ({
                    ...e,
                    hitCount: dsVisitCounts.dataSetViewCount[e.id] ? dsVisitCounts.dataSetViewCount[e.id] : 0
                }));
                setDataSets(dSets);
            }
        } catch (e) {}
    }, [dsVisitCounts]);

    useEffect(() => {
        if (data && !isLoading) {
            setSkip(false);
            setDataSets(data);
        }
        if (location.pathname.length != 0 && location.pathname != "/") {
            const dsID = location.pathname.split("/").pop();
            if (data) {
                const curDataset = data?.filter((e) => e.id === Number(dsID));
                if (curDataset && curDataset[0] != null && curDataset[0].name != null) {
                    setDsName(curDataset[0].name);
                }
            }
        } else if (location.pathname == "/") {
            setDsName("");
        }
    }, [data]);

    useEffect(() => {
        TelemetryService.initialize(telemetryConfig);
        // Call to check SIPPY is enabled or not in the environemnt
        dispatch(IsSippyEnabledAction("IsSippyFeatureEnabled"));
        // Initiate ask sippy hide action
        setInterval(() => {
            let chatTab = document.querySelector("div[class*='chatTab']") as HTMLElement;
            if (chatTab !== null && chatTab.classList != null && !chatTab.classList.contains("chatTabHide")) {
                chatTab.classList.add("chatTabHide");
            } else if (chatTab !== null && chatTab.classList != null && chatTab.classList.contains("chatTabHide")) {
                chatTab.classList.remove("chatTabHide");
            }
        }, 10000);
    }, []);

    useEffect(() => {
        // Ensure only site page visit is logged and skipping url with refresh token and error details
        if (window.location.href.indexOf("#") === -1) {        
            const pageInfo: IPageViewTelemetry = {
                name: document ? document.title : "",
                uri: window.location.href.toLowerCase(),
                properties: { user_Id: getUserName(), user_DisplayName: getUserDisplayName() }
            };
            TelemetryService.trackPageView(pageInfo);
        }       
        if (location.pathname.length != 0 && location.pathname != "/") {
            const dsID = location.pathname.split("/").pop();
            if (data) {
                const curDataset = data?.filter((e) => e.id === Number(dsID));
                if (curDataset && curDataset[0] != null && curDataset[0].name != null) {
                    setDsName(curDataset[0].name);
                }
            }
        } else if (location.pathname == "/") {
            setDsName("");
        }
    }, [location.pathname]);

    function onFeedBackClick(): boolean {
        setDisplayFeedback(true);
        return true;
    }
    async function forceRefresh() {
        try {
            await dataCatalogClient.get(`datasets/clearCache`);
            await dataCatalogClient.get(`datasets/clearDataSetDetailsCache`);
            await dataCatalogClient.get(`datasets/ClearDataSetSummaryCache`);
            await dataCatalogClient.get(`datasets/ClearRoleGroupOwnerCache`);
            refetch();
        } catch (error) {
            console.error(error);
        }
    }

    function OpenChatWindow() {
        setShowChat(true);
    }

    // Panel Header with Tooltip
    const onRenderCustomHeader = (props?: IPanelProps, defaultRender?: IRenderFunction<IPanelProps>) => {
        return (
            <div style={{ display: "inline", width: "100%" }}>
                {typeof defaultRender === "function" && defaultRender(props)}
                <Icon
                    style={{ cursor: "pointer" }}
                    iconName="info"
                    title="Please do not provide any sensitive information while using this service as the conversation may be logged!"
                />
            </div>
        );
    };

    return (
        <>
            <DataPrivacyAlert />
            <FeedbackModal show={displayFeedback} onClose={() => setDisplayFeedback(false)} />

            <Header
                title={
                    <Image
                        src={Logo}
                        height={40}
                        alt="Security Intelligence Platform Data Catalog"
                        onClick={() => navigate("/")}
                        style={{ cursor: "pointer" }}
                    />
                }
                logout={msalInstance.logout}
                getUserPhoto={() => getUserPhoto(account, msalInstance)}
                helpPanelSettings={{ title: "Help", content: getHelpPanelContent() }}
                account={account}
                notificationItems={notifications}
                shouldShowAdminActions={isAdmin}
                additionalItems={[
                    { iconName: "refresh", title: "Force refresh of cache", isAdmin: true, onClick: forceRefresh },
                    {
                        iconName: "PreviewLink",
                        title: "Enable preview features",
                        isAdmin: true,
                        onClick: () => dispatch(setIsPreviewEnabled(!isPreviewEnabled))
                    },
                    {
                        iconName: "Feedback",
                        title: "Feedback",
                        isAdmin: false,
                        onClick: () => onFeedBackClick()
                    }
                ]}
                hideSignOut={true}
            />
            <Routes>
                <Route
                    path="dataset/:id"
                    element={
                        <RbacGuard
                            Element={
                                <div style={{ padding: "20px" }}>
                                    {" "}
                                    <InfoPanelContent />
                                </div>
                            }
                            roles={AccessRoles}
                        />
                    }
                />
                <Route
                    path="/"
                    element={
                        <RbacGuard
                            Element={<DatasetsView allDataSets={dataSets} isLoading={isLoading} my={false} />}
                            roles={AccessRoles}
                        />
                    }
                />

                <Route path="*" element={<NotFound />} />

                <Route
                    path="/collections/:accessPackageId"
                    element={<RbacGuard Element={<DatasetCollectionGrid />} roles={AccessRoles} />}
                />

                <Route path="*" element={<NotFound />} />
                <Route path="/requestAccess" element={<AuthenticatedAccessRequestPage />} />
                <Route
                    path="/OBOAccessRequest/:aPkgRefId/:id/:aPkgName"
                    element={<RbacGuard Element={<OBOAccessRequestIntake />} roles={AccessRoles} />}
                />

                <Route
                    path="/OBORoleGroupAccessRequest/:collectionAccessPkgId"
                    element={<RbacGuard Element={<RoleGroupOboRequestForm />} roles={AccessRoles} />}
                />
            </Routes>
            <Panel
                isOpen={showChat}
                onDismiss={() => {
                    setShowChat(false);
                }}
                onRenderHeader={onRenderCustomHeader}
                type={PanelType.large}
                closeButtonAriaLabel="Close"
                headerText={"Ask Sippy"}
                styles={{
                    header: {
                        display: "inline-flex",
                        paddingRight: 6
                    },
                    contentInner: {
                        marginBottom: "170px",
                        backgroundPosition: "center",
                        backgroundSize: "60%",
                        backgroundImage: `linear-gradient(rgba(255,255,255,0.91), rgba(255,255,255,0.99)), url(${sippyLogo})`,
                        backgroundcolor: "rgba(255,255,255,0.6)",
                        backgroundRepeat: "no-repeat"
                    }
                }}
                headerTextProps={{ style: { fontSize: 20, fontWeight: "bold" } }}
                isBlocking={true}
            >
                <div
                    className="chatContainer"
                    style={{
                        display: "flex",
                        position: "absolute",
                        bottom: "0px",
                        overflowY: "auto",
                        width: "96%",
                        maxHeight: "100%"
                    }}
                >
                    <SippyChatBot datasetName={dsName} containerWidth="1450px" />
                </div>
            </Panel>

            <RbacGuard
                Element={
                    isSippyFeatureEnabled && !showChat && location.pathname.indexOf("/collections") === -1 ? (
                        <div id="SIPChatbutton">
                            <div className="chatTab">
                                <img
                                    src={AskSippyLogo}
                                    alt="chat"
                                    onClick={() => {
                                        OpenChatWindow();
                                    }}
                                    style={{ width: "150px" }}
                                ></img>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
                roles={AccessRoles}
            />
        </>
    );
};

export const App = ({ msalInstance }: { msalInstance: IPublicClientApplication }) => {
    msalInstance.handleRedirectPromise().then((result) => {
        if (result) {
            msalInstance.setActiveAccount(result.account);
        }
        return result;
    });
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                authenticationRequest={authenticationParameters}
                interactionType={InteractionType.Redirect}
            >
                <ThemeProvider {...CoherenceCustomizations}>
                    <Provider store={store}>
                        <BrowserRouter>
                            <Inner msalInstance={msalInstance} />
                        </BrowserRouter>
                    </Provider>
                </ThemeProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};
