import { combineReducers } from "@reduxjs/toolkit";
import { getSchemaApi } from "../../features/DataSets/SchemaViews/slice";
import { allDataSetsApi } from "../../features/DataSets/slice";
import errorSlice from "./errorReducer";
import notificationsSlice from "./notificationsReducer";
import { pageViewsAPI } from "./pageviewReducer";
import { getDataQualityApi } from "../../features/DataSets/Content/AdlsDataQuality";
import { datasetOwnersApi } from "../../features/DataSets/datasetOwnerSlice";
import { favoriteDatasetSlice } from "./favoriteDatasetReducer";
import { getSDWDataQualityApi, getKustoDataQualityApi } from "./dataQualityReducer";
import { adlsIngestionTrendApi } from "./adlsIngestionTrendReducer";
import { collectionsApi } from "../../features/DataSetCollections/slice";
import userAccessRequestSlice from "../../features/UnAuthenticated/slice";
import searchFilterSlice from "../../features/Search/slice";
import { sippyChatSlice } from "./sippyChatReducer";
import { pageViewsByURLSlice } from "./pageViewsByUrlReducer";
const rootReducer = combineReducers({
    errors: errorSlice,
    notifications: notificationsSlice,
    favoriteDatasets: favoriteDatasetSlice.reducer,
    pageViews: pageViewsByURLSlice.reducer,
    sippyChat: sippyChatSlice.reducer,
    [pageViewsAPI.reducerPath]: pageViewsAPI.reducer,
    [allDataSetsApi.reducerPath]: allDataSetsApi.reducer,
    [getSchemaApi.reducerPath]: getSchemaApi.reducer,
    [getDataQualityApi.reducerPath]: getDataQualityApi.reducer,
    [getSDWDataQualityApi.reducerPath]: getSDWDataQualityApi.reducer,
    [getKustoDataQualityApi.reducerPath]: getKustoDataQualityApi.reducer,
    [datasetOwnersApi.reducerPath]: datasetOwnersApi.reducer,
    [adlsIngestionTrendApi.reducerPath]: adlsIngestionTrendApi.reducer,
    [collectionsApi.reducerPath]: collectionsApi.reducer,
    userAccessRequests: userAccessRequestSlice,
    searchFilter: searchFilterSlice
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
